<template>
  <main class="dashboard df">
    <aside class="aside-nav-container" data-simplebar>
      <h2 class="aside-nav-user">{{prevUser.id ? 'Logged as' : 'Hello,'}} {{ user.email }}</h2>
      <h3 v-if="prevUser.id" class="aside-nav-prev-user">Main user: {{prevUser.email }}</h3>
      <p class="aside-nav-time">{{ time }}</p>
      <ul class="aside-nav-list">
        <li class="aside-nav-item active">
          <router-link to="/dashboard/articles"
                       class="aside-nav-btn">
            <File/>
            <span>Articles Overview</span>
            <Arrow class="arrow"/>
          </router-link>
<!--          <ul v-if="user.role === 'USER'" class="aside-under-list active" ref="articles-list">-->
<!--            <li class="aside-under-item" v-for="(article, idx) in articles" :key="`articles-${idx}`">-->
<!--              <router-link class="aside-under-link" :to="`/dashboard/articles/${article.link}`">-->
<!--                {{ article.title }}-->
<!--              </router-link>-->
<!--            </li>-->
<!--          </ul>-->
        </li>
        <li class="aside-nav-item" v-for="(item, idx) in navList" :key="`nav-list-${idx}`">
          <router-link :to="item.link" class="aside-nav-btn">
            <component :is="item.icon"/>
            <span>{{ item.name }}</span>
          </router-link>
        </li>
        <li class="aside-nav-item">
          <button @click="logoutUser" class="aside-nav-btn">
            <Logout/>
            <span>Logout</span>
          </button>
        </li>
<!--        <li class="aside-nav-item" v-if="user.role === 'ADMIN'">-->
<!--          <router-link to="/" class="aside-nav-btn">-->
<!--            <Manual/>-->
<!--            <span>Manual Check</span>-->
<!--          </router-link>-->
<!--        </li>-->
      </ul>
      <p v-if="user.role !== 'USER'" class="aside-nav-bal-tru">
        Bal tru: {{ balTru }}
      </p>
    </aside>
    <simplebar class="main-container">
      <slot></slot>
    </simplebar>
  </main>
</template>

<script>
import Plus from '@/assets/icons/plus-circle.svg';
import Trash from '@/assets/icons/archive.svg';
import Users from '@/assets/icons/users.svg';
import Last from '@/assets/icons/umbrella.svg';
import Comment from '@/assets/icons/message.svg';
import Setting from '@/assets/icons/settings.svg';
import Logout from '@/assets/icons/log-out.svg';
import Arrow from '@/assets/icons/arrow-down.svg';
import File from '@/assets/icons/file-text.svg';
import Ready from '@/assets/icons/checkmark-circle.svg';
import Manual from '@/assets/icons/manual.svg';
import toggleSlide from "@/mixins/toggleSlide";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "NavWrapper",
  mixins: [toggleSlide],
  components: {
    Logout, Arrow, File, Plus, Trash, Users, Comment, Last, Setting, Ready, Manual
  },
  data() {
    return {
      userName: 'User',
      time: null,
      balTru: 0,
      articles: []
    }
  },
  computed: {
    ...mapGetters(['user', 'prevUser']),
    navList(){
      let list = [
        {
          name: 'Last Changes',
          link: '/dashboard/last-changes',
          icon: 'Last'
        },
        {
          name: 'Trashbox',
          link: '/dashboard/trashbox',
          icon: 'Trash'
        },
        {
          name: 'Users',
          link: '/dashboard/users',
          icon: 'Users'
        },
        {
          name: 'Last Comments',
          link: '/dashboard/last-comments',
          icon: 'Comment'
        },
        // {
        //   name: 'Service and Settings',
        //   link: '/dashboard/settings',
        //   icon: 'Setting'
        // }
      ];
      if(['ADMIN', 'SUPERADMIN'].includes(this.user.role)){
        list = [{
          name: 'Ready articles',
          link: '/dashboard/ready',
          icon: 'Ready'
        },{
          name: 'Public articles',
          link: '/dashboard/public',
          icon: 'File'
        }, ...list]
      }
      return this.user.role === 'USER' ? [] : list;
    }
  },
  methods: {
    ...mapActions(['logout', 'getTextruBalance']),
    logoutUser(){
      if(this.prevUser.id){
        localStorage.removeItem('prevUser');
        window.location.reload();
      } else {
        this.logout();
      }
    },
    updateCurrentTime() {
      this.time = this.$moment().format('HH:mm:ss');
    },
    toggle(ref) {
      this.toggleSlide(this.$refs[ref], () => {
        this.$refs[ref].parentElement.classList.toggle('active');
      })
    },
    async getBalance(){
      await this.getTextruBalance().then(data => {
        this.balTru = data.size;
      }).catch(error => {
        this.$notify({
          title: 'TextRu Balance',
          text: error.response.data.message,
          type: 'error'
        });
      });
    }
  },
  async created() {
    this.time = this.$moment().format('HH:mm:ss');
    setInterval(() => this.updateCurrentTime(), 1 * 1000);
    if(this.user.role !== 'USER') {
      await this.getBalance();
    }
  }
}
</script>

<style scoped>

</style>
